.ais-SearchBox-input {
  width: 100%;
  height: 3.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.75rem;
}

.ais-SearchBox-input:focus {
  outline: none;
}

.ais-SearchBox-form {
}

.ais-SearchBox-submitIcon {
  display: none;
}

.ais-SearchBox-submitIcon {
  display: none;
}

.ais-SearchBox-resetIcon {
  display: none;
}

.ais-SearchBox-reset {
  display: none;
}

.ais-SearchBox-loadingIndicator {
  display: none;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.ais-Highlight {
  color: black;
}
